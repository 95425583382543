import { LitElement, html, css } from 'lit';
import install from '@twind/with-web-components';
import config from '../../twind.config';

const withTwind = install(config);

class BindAccountDialog extends withTwind(LitElement) {
  static properties = {
    open: { type: Boolean },
  };

  constructor() {
    super();
    this.open = false;
  }

  static styles = css`
    .modal-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }

    .modal-content {
      background: white;
      padding: 30px;
      border-radius: 8px;
      width: 400px;
      min-width: 418px;
      min-height: 232px;
      max-width: 90%;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }

    .modal-header {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .modal-body {
      flex: 1;
      margin-bottom: 20px;
      text-align: left;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
    }

    .confirm {
      background-color: #56C7BB;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .confirm:hover {
      background-color: #45a6a1;
    }

    .close-btn {
      position: absolute;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
    }
  `;

  render() {
    if (!this.open) return html``;

    return html`
      <div class="modal-container" @click="${this.close}">
        <div class="modal-content" @click="${e => e.stopPropagation()}">
          <div class="modal-header">
            <span>綁定帳號快速登入</span>
            <button class="close-btn" @click="${this.close}">&times;</button>
          </div>
          <div class="modal-body">
            歡迎來到W101，若欲使用其他登入方式快速登入，可至:帳號設定>連結帳號>綁定帳號
          </div>
          <div class="modal-footer">
            <button class="confirm" @click="${this.bindAccount}">至帳號設定綁定</button>
          </div>
        </div>
      </div>
    `;
  }

  close() {
    this.open = false;
  }

  bindAccount() {
    // 跳轉到帳號設定頁面
    window.location.href = '/user/link-account';
  }
}

customElements.define('bind-account-dialog', BindAccountDialog);